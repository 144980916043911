/* eslint-disable no-unused-vars */
import instance from "@/service/instance";

const URL = "/dashboard";
const URL_REPORT_WIDGET = "/widget";

export const updateDashboardPeriod = async (dashboard) => {
  const { id, ...restDashboard } = dashboard;
  if (!id) {
    throw new Error("ID is required to update period!");
  }
  const { data } = await instance.put(`${URL}/${id}/period`, restDashboard);
  return data;
};

export const getDashboardAllWidgets = async (dashboardId) => {
  const { data } = await instance(`${URL}/${dashboardId}`);
  const dashboard = data?.data || {};
  return dashboard;
};

export const getDashboardsUserPermitted = async (userId) => {
  const { data } = await instance(`${URL}/permitted/${userId}`);
  return data?.data || [];
};

export const getDashboardExport = async (token) => {
  const { data } = await instance(`export/${token}`);
  return data?.data || [];
};

export const postDashboardContact = async (dashboardId, payload) => {
  const { data } = await instance.post(
    `${URL}/${dashboardId}/contact`,
    payload
  );
  return data?.data || {};
};

export const updateReportWidget = async (payload) => {
  const { data } = await instance.put(
    `${URL_REPORT_WIDGET}/${payload.widgetId}`,
    payload,
    {
      timeout: 300000,
    }
  );
  return data;
};
