export const verifyLanguage = (widget, language) => {
  let verifiedWidget = { ...widget };
  if (verifiedWidget?.settings) {
    verifiedWidget.settings = {
      ...verifiedWidget.settings,
      language,
    };
  } else {
    verifiedWidget = {
      ...verifiedWidget,
      settings: { language },
    };
  }
  return verifiedWidget;
};
