import { ACTIONS } from "@/constants/vuex";
import { getUserInfo } from "@/service/user";
import store from "@/store";
import { FORGOT_PASSWORD_PATH } from "../routes/forgotPassword";
import { NOT_FOUND_PATH } from "../routes/notFound";
import RESET_PASSWORD from "../routes/resetPassword";

const PREVIEW_PATH = "v";

export default (router) => {
  router.beforeEach(async (to, _, next) => {
    if (to.fullPath === NOT_FOUND_PATH) {
      return next();
    }

    const userId = store.state.user.logged.id;
    if (userId) {
      // usuario logado entra onde quiser? até em outro dashboard?
      // por isso que precisa validar o subscriber no componente tb!
      return next();
    }

    const pathFields = to.fullPath.split("/");
    let portalName = pathFields[1];
    const resourcePath = pathFields[2];
    const portalNameSplitted = portalName?.split("?");
    const language = portalNameSplitted[1];
    portalName = portalNameSplitted[0] || portalName;

    if (portalName === PREVIEW_PATH) {
      return next();
    }

    await store.dispatch(`portal/${ACTIONS.PORTAL.GET_INFO}`, portalName);
    if (!store.state.portal.settings.domainName) {
      return next({ path: NOT_FOUND_PATH });
    }

    if (
      resourcePath == FORGOT_PASSWORD_PATH ||
      to.name == RESET_PASSWORD.name
    ) {
      return next();
    }

    const portalSettings = store.state.portal.settings;
    const user = await getUserInfo();

    if (!user.id) {
      if (to.fullPath === `/${portalSettings.domainName}` && !to.query.lang) {
        return next({ query: { lang: language }, replace: true });
      }
      if (language) {
        return next();
      } else {
        return next({ path: portalSettings.domainName });
      }
    }
    await store.dispatch(`user/${ACTIONS.USER.GET_INFO}`, user);
    if (
      store.state.portal.settings.subscriber !==
      store.state.user.logged.subscriberId
    ) {
      return next({ path: NOT_FOUND_PATH });
    }
    if (!resourcePath) {
      return next({
        path: `${portalSettings.domainName}/home`,
        params: { lang: language },
      });
    }
    return next();
  });
};
