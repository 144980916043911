import actions from "@/store/dashboard/actions";
import mutations from "@/store/dashboard/mutations";
import state from "@/store/dashboard/state";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
