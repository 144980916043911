export const ACTIONS = {
  DASHBOARD: {
    LOGIN: "LOGIN",
    GET_DASHBOARD: "GET_DASHBOARD",
    SELECT_PERIOD: "SELECT_PERIOD",
    CONTACT: "CONTACT",
    UPDATE_WIDGET_LOAD: "UPDATE_WIDGET_LOAD",
  },
  USER: {
    GET_INFO: "GET_INFO",
    LOGOUT: "LOGOUT",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
  },
  PORTAL: {
    GET_INFO: "GET_INFO",
  },
};

export const MUTATIONS = {
  DASHBOARD: {
    SET_DASHBOARD: "SET_DASHBOARD",
    FIX_LAYOUT_HEIGHT: "FIX_LAYOUT_HEIGHT",
    FIX_EMPTY_WIDGET_HEIGHT: "FIX_EMPTY_WIDGET_HEIGHT",
    UPDATE_WIDGET_LAYOUT: "UPDATE_WIDGET_LAYOUT",
    UPDATE_WIDGET_LOAD: "UPDATE_WIDGET_LOAD",
    SET_LOADING: "SET_LOADING",
    SET_ITEM: "SET_ITEM",
  },
  USER: {
    SET_USER: "SET_USER",
    REMOVE_USER_LOGGED: "REMOVE_USER_LOGGED",
  },
  LOADING: {
    SET_LOADING: "SET_LOADING",
  },
  PORTAL: {
    SET_SETTINGS: "SET_SETTINGS",
  },
};
