import "@engenharia/dashgoo-ui/dist/styles/common.css";
import Vue from "vue";
import App from "@/App.vue";
import i18n from "@/lib/i18n";
import router from "@/router";
import store from "@/store";
import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

// inicializamos o Bugsnag somente em produção
if (process.env.NODE_ENV === "production") {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginVue()],
  });
  BugsnagPerformance.start({ apiKey: process.env.VUE_APP_BUGSNAG_KEY });

  const bugsnagVue = Bugsnag.getPlugin("vue");
  bugsnagVue.installVueErrorHandler(Vue);
}

const requirePlugins = require.context("./plugins/", true, /\.(js)$/i);
requirePlugins.keys().forEach((key) => {
  if (!requirePlugins(key).default) return;
  Vue.use(requirePlugins(key).default);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
