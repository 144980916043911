import { VUE_APP_API_URL } from "@/constants/enviroments";
import Axios from "axios";

const instance = Axios.create({
  baseURL: VUE_APP_API_URL,
  withCredentials: true,
  timeout: 30000,
});

function handleError(response) {
  const errMsg =
    response && response.data && response.data.message
      ? response.data.message
      : "SOMETHING_IS_WRONG";

  return errMsg;
}

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    const { data = {} } = response || {};
    data.message = handleError(error.response);
    return Promise.reject(data);
  }
);

export default instance;
