import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "ERRORS.CAN_NOT_BE_EMPTY",
});

extend("email", {
  ...email,
  message: "INVALID_EMAIL_ADDRESS",
});

extend("confirm", {
  params: ["target"],
  validate: (value, { target }) => {
    return value === target;
  },
  message: "PASSWORDS_NOT_MATCH",
});

extend("dateRangeRequired", {
  validate: (value) => {
    const dateRangeIsValid =
      Array.isArray(value) &&
      value.every((element) => element instanceof Date && !isNaN(element));

    return dateRangeIsValid;
  },
  message: "ERRORS.CAN_NOT_BE_EMPTY",
});
