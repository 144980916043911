export const INITIAL_STATE = {
  layout: [],
  period: "",
  selectedDashboard: undefined,
  settings: undefined,
  dashboardLanguage: undefined,
  item: undefined,
  loading: false,
};

export default INITIAL_STATE;
