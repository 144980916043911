import { ACTIONS, MUTATIONS } from "@/constants/vuex";
import { lookupPortal } from "@/service/user";

export default {
  [ACTIONS.PORTAL.GET_INFO]: async ({ commit }, domainName) => {
    const portalInfo = await lookupPortal({ portal: domainName });
    commit(MUTATIONS.PORTAL.SET_SETTINGS, {
      settings: portalInfo,
    });
  },
};
