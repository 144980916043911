import Vue from "vue";
import VueRouter from "vue-router";
import { BaseRouter } from "@/router/Base";
import beforeEach from "@/router/guard/beforeEach";

Vue.use(VueRouter);

const routes = BaseRouter;

const router = new VueRouter({
  mode: "history",
  routes,
});

beforeEach(router);

export default router;
