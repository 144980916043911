import { isEmpty } from "@engenharia/dashgoo-widgets";

class FixEmptyWidgetHeight {
  constructor(layout) {
    this._layout = layout;
  }

  isWidgetEmpty(widget) {
    return isEmpty(widget.item, widget.item.type);
  }

  fixHeight() {
    const layoutToLoop = [...this._layout];

    let lastLayoutBreakIndex = -1;
    let lastLayoutBreakY = -1;

    for (let i = 0; i < layoutToLoop.length; i++) {
      const item = layoutToLoop[i];

      if (item.i.includes("layout-break")) {
        lastLayoutBreakIndex = i;
        lastLayoutBreakY = item.y;
        continue; // Adicionamos este continue para não alterar os layout breaks
      }

      if (!this.isWidgetEmpty(item)) {
        if (lastLayoutBreakIndex !== -1 && lastLayoutBreakIndex < i) {
          item.y = Math.max(lastLayoutBreakY, item.y);
        }
      } else {
        if (i < layoutToLoop.length - 1) {
          const nextItem = layoutToLoop[i + 1];
          if (!nextItem.i.includes("layout-break")) {
            nextItem.y = item.y + item.h;
          }
        }
      }
    }

    const fixedLayoutHeights = layoutToLoop.filter(
      (widget) => !this.isWidgetEmpty(widget)
    );

    return fixedLayoutHeights;
  }
}
export default FixEmptyWidgetHeight;
