import AUTH from "@/router/routes/auth";
import FORGOT_PASSWORD from "@/router/routes/forgotPassword";
import HOME from "@/router/routes/home";
import NOT_FOUND from "@/router/routes/notFound";
import RESET_PASSWORD from "@/router/routes/resetPassword";

export const BaseRouter = [
  {
    ...NOT_FOUND,
    component: () => import("@/pages/NotFound.vue"),
  },
  {
    path: "/v/:token",
    component: () => import("@/pages/Preview.vue"),
  },
  {
    path: "/:id",
    component: () => import("@/layouts/Auth.vue"),
    children: [
      {
        ...AUTH,
        component: () => import("@/pages/AuthLogin.vue"),
      },
    ],
  },
  {
    path: "/:id",
    component: () => import("@/layouts/ForgotPassword.vue"),
    children: [
      {
        ...FORGOT_PASSWORD,
        component: () => import("@/pages/AuthForgotPassword.vue"),
      },
    ],
  },
  {
    path: "/:id",
    component: () => import("@/layouts/ResetPassword.vue"),
    children: [
      {
        ...RESET_PASSWORD,
        component: () => import("@/pages/AuthResetPassword.vue"),
      },
    ],
  },
  {
    path: "/:id",
    component: () => import("@/layouts/Logged.vue"),
    children: [
      {
        ...HOME,
        component: () => import("@/pages/Home.vue"),
      },
    ],
  },
];
