import { MUTATIONS } from "@/constants/vuex";
import FixEmptyWidgetHeight from "@/utils/fixEmptyWidgetHeight";
import { verifyLanguage } from "@/utils/verifyWidgetLanguage";
import { PAGE_BREAK, LAYOUT_BREAK, isEmpty } from "@engenharia/dashgoo-widgets";
export default {
  [MUTATIONS.DASHBOARD.SET_ITEM]: (state, payload) => {
    state.item = payload;
  },
  [MUTATIONS.DASHBOARD.SET_DASHBOARD]: (state, payload) => {
    const { id, widgets = [], settings, client, updatedAt } = payload;
    const layout = [];
    for (let index = 0; index < settings.layout.length; ++index) {
      const { i: layoutId, ...restLayout } = settings.layout[index] || {};
      const isPageBreak = layoutId.includes(PAGE_BREAK.dataType);
      const isLayoutBreak = layoutId.includes(LAYOUT_BREAK.dataType);

      if (layoutId) {
        const defPageBreak = {
          id: layoutId,
          type: PAGE_BREAK.dataType,
        };
        const defLayoutBreak = {
          id: layoutId,
          type: LAYOUT_BREAK.dataType,
        };

        const widget = (() => {
          if (isPageBreak) return defPageBreak;
          if (isLayoutBreak) return defLayoutBreak;
          return widgets.find(({ id: widgetId }) => layoutId === widgetId);
        })();

        if (widget && !isEmpty(widget, widget.type)) {
          layout.push({
            ...restLayout,
            i: layoutId,
            isResizable: false,
            item: widget,
            loading: false,
            updatedAt: updatedAt,
            minH: 1,
          });
        }
      }
    }

    state.selectedDashboard = id;
    state.layout = layout;
    state.period = payload.period;
    state.settings = {
      timePeriod: settings.timePeriod,
      comparisonTimePeriod: settings.comparisonTimePeriod,
      comparisonDateRangeStart: settings.comparisonDateRangeStart,
      comparisonDateRangeEnd: settings.comparisonDateRangeEnd,
      dateRangeStart: settings.dateRangeStart,
      dateRangeEnd: settings.dateRangeEnd,
    };
    state.dashboardLanguage = client?.settings?.language;
  },
  [MUTATIONS.DASHBOARD.FIX_LAYOUT_HEIGHT]: (
    state,
    { layoutId, height, minHeight }
  ) => {
    const layout = [...state.layout];
    const layoutIndex = layout.findIndex(
      (currentLayout) => currentLayout.i === layoutId
    );
    if (layoutIndex === -1) return;
    const selectedLayout = layout[layoutIndex];

    // Calcular a diferença na altura
    const heightDiff = height - selectedLayout.h;

    layout[layoutIndex] = {
      ...selectedLayout,
      h: height,
      minH: minHeight,
    };

    // Ajustar a posição Y dos widgets subsequentes
    for (let i = layoutIndex + 1; i < layout.length; i++) {
      layout[i].y += heightDiff;
    }

    state.layout = layout;
  },
  [MUTATIONS.DASHBOARD.FIX_EMPTY_WIDGET_HEIGHT]: (
    state,
    { payloadLayout, language }
  ) => {
    let listLayout = [];
    listLayout = new FixEmptyWidgetHeight(payloadLayout).fixHeight();
    listLayout = listLayout.map((widget) => {
      return { ...widget, item: verifyLanguage(widget.item, language) };
    });

    state.layout = listLayout;
  },
  [MUTATIONS.DASHBOARD.UPDATE_WIDGET_LAYOUT]: (state, payload) => {
    const layout = [...state.layout];
    const widgets = [...state.item.widgets];

    if (Array.isArray(payload)) {
      payload.forEach((payloadItem) => {
        // Encontrar o índice do item no layout e nos widgets.
        const layoutIndex = layout.findIndex(
          ({ i: layoutId }) => layoutId === payloadItem.i
        );
        const widgetIndex = widgets.findIndex(
          ({ id }) => id === payloadItem.item.id
        );

        // Se o item foi encontrado no layout, atualizá-lo
        if (layoutIndex !== -1) {
          layout[layoutIndex] = payloadItem;
        }

        // Se o item foi encontrado nos widgets, atualizá-lo
        if (widgetIndex !== -1) {
          widgets[widgetIndex] = payloadItem.item;
        }
      });
    } else {
      const layoutIndex = layout.findIndex(
        ({ i: layoutId }) => layoutId === payload.id
      );
      const widgetIndex = widgets.findIndex(({ id }) => id === payload.id);

      layout[layoutIndex].item = payload;
      widgets[widgetIndex] = payload;
    }

    state.layout = layout;
    state.item.widgets = widgets;
  },
  [MUTATIONS.DASHBOARD.SET_LOADING]: (state, loading) => {
    state.loading = loading;
  },
  [MUTATIONS.DASHBOARD.UPDATE_WIDGET_LOAD]: (state, payload) => {
    const layout = [...state.layout];

    const layoutIndex = layout.findIndex(
      ({ i: layoutId }) => layoutId === payload.id
    );

    layout[layoutIndex].loading = payload.loading;

    state.layout = layout;
  },
};
