import { USER_DASHBOARD } from "@/constants/userType";
import instance from "@/service/instance";

export const fetchLogin = async (params) => {
  const { data } = await instance.post("/login", params);
  return data;
};

export const fetchLogout = async () => {
  const { data } = await instance.get("/logout");

  return data;
};

export const fetchResetPassword = async (payload) => {
  const resetInfo = {
    ...payload,
    userType: USER_DASHBOARD,
  };
  const { data } = await instance.post(`/reset-password`, resetInfo);
  return data;
};

export const fetchChangePassword = async (payload) => {
  const { data } = await instance.post("/change-password", payload);
  return data;
};
