import actions from "@/store/user/actions";
import mutations from "@/store/user/mutations";
import state from "@/store/user/state";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
