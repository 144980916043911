export const PT_BR = Object.freeze({
  label: "Português (BR)",
  value: "pt-BR",
  currency: "BRL",
});

export const EN_US = Object.freeze({
  label: "English",
  value: "en-US",
  currency: "USD",
});

export const ES_ES = Object.freeze({
  label: "Español",
  value: "es-ES",
  currency: "EUR",
});

export const PT_PT = Object.freeze({
  label: "Português (PT)",
  value: "pt-PT",
  currency: "EUR",
});

export const DEFAULT_LANGUAGE = PT_BR.value;

export const LIST = Object.freeze([EN_US, PT_BR, ES_ES, PT_PT]);
