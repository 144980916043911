import Vue from "vue";
import Vuex from "vuex";
import dashboard from "@/store/dashboard";
import loading from "@/store/loading";
import portal from "@/store/portal";
import user from "@/store/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    loading,
    dashboard,
    portal,
  },
});
