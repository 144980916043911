import Vue from "vue";
import VueI18n from "vue-i18n";
import { DEFAULT_LANGUAGE } from "@/constants/language";

class CustomFormatter {
  interpolate(message, values) {
    let formattedMessage = message;
    const REGEX_KEYS = new RegExp(/{{\w+}}|\${\w+}/g);
    if (values) {
      Object.entries(values).forEach(([key, text]) => {
        const regex = new RegExp(`{{${key}}}|\\\${${key}}`);
        formattedMessage = formattedMessage.replace(regex, text);
      });
    } else {
      const contains = formattedMessage.match(REGEX_KEYS);
      if (contains) formattedMessage = formattedMessage.replace(REGEX_KEYS, "");
    }
    return formattedMessage;
  }
}

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "../../public/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/\/([a-z]){2}(-([A-Z]){2}){0,1}\//i);
    if (matched && matched.length > 1) {
      const locale = matched[0].replace(/[/]/g, "");
      messages[locale] = {
        ...locales(key),
      };
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  formatter: new CustomFormatter(),
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
});

export default i18n;
