import { MUTATIONS } from "@/constants/vuex";
import INITIAL_STATE from "@/store/user/state";

export default {
  [MUTATIONS.USER.SET_USER]: (state, { user, dashboards }) => {
    state.logged = user;
    state.dashboards = dashboards;
  },
  [MUTATIONS.USER.REMOVE_USER_LOGGED]: (state) => {
    state.logged = INITIAL_STATE.logged;
    state.dashboards = INITIAL_STATE.dashboards;
    localStorage.clear();
  },
};
