import { ACTIONS, MUTATIONS } from "@/constants/vuex";
import {
  updateDashboardPeriod,
  getDashboardAllWidgets,
  postDashboardContact,
} from "@/service/dashboard";

export default {
  [ACTIONS.DASHBOARD.GET_DASHBOARD]: async ({ commit, rootState }, payload) => {
    try {
      commit(`loading/${MUTATIONS.LOADING.SET_LOADING}`, true, {
        root: true,
      });
      const { dashboardId } = payload || {};
      const [firstUserDashboard] = rootState.user.dashboards;
      const dashboard = await getDashboardAllWidgets(
        dashboardId || firstUserDashboard.id
      );
      const language = rootState.dashboard.dashboardLanguage;
      commit(MUTATIONS.DASHBOARD.SET_ITEM, dashboard);
      commit(MUTATIONS.DASHBOARD.SET_DASHBOARD, dashboard);
      commit(MUTATIONS.DASHBOARD.FIX_EMPTY_WIDGET_HEIGHT, {
        payloadLayout: rootState.dashboard.layout,
        language,
      });
    } finally {
      commit(`loading/${MUTATIONS.LOADING.SET_LOADING}`, false, {
        root: true,
      });
    }
  },
  [ACTIONS.DASHBOARD.CONTACT]: async ({ commit, rootState }, payload) => {
    try {
      commit(`loading/${MUTATIONS.LOADING.SET_LOADING}`, true, {
        root: true,
      });
      const dashboardId = rootState.dashboard.selectedDashboard;
      await postDashboardContact(dashboardId, payload);
    } finally {
      commit(`loading/${MUTATIONS.LOADING.SET_LOADING}`, false, {
        root: true,
      });
    }
  },
  [ACTIONS.DASHBOARD.SELECT_PERIOD]: async ({ commit, rootState }, payload) => {
    try {
      commit(`loading/${MUTATIONS.LOADING.SET_LOADING}`, true, {
        root: true,
      });
      const {
        dateRange,
        comparisonDateRange,
        timePeriod,
        comparisonTimePeriod,
      } = payload;

      const startDate = dateRange[0].toISOString();
      const endDate = dateRange[1].toISOString();
      const comparisonStartDate = comparisonDateRange[0].toISOString();
      const comparisonEndDate = comparisonDateRange[1].toISOString();
      const dashboardId = rootState.dashboard.selectedDashboard;

      const dashboardParams = {
        id: dashboardId,
        settings: {
          timePeriod: timePeriod,
          comparisonTimePeriod: comparisonTimePeriod,
          dateRangeStart: startDate,
          dateRangeEnd: endDate,
          comparisonDateRangeStart: comparisonStartDate,
          comparisonDateRangeEnd: comparisonEndDate,
        },
      };

      if (dashboardId) {
        const language = rootState.dashboard.dashboardLanguage;
        const periodSettings = await updateDashboardPeriod(dashboardParams);
        const [firstUserDashboard] = rootState.user.dashboards;
        const dashboard = await getDashboardAllWidgets(
          dashboardId || firstUserDashboard.id
        );

        dashboard.settings = {
          ...dashboard.settings,
          timePeriod: periodSettings?.settings?.timePeriod,
          comparisonTimePeriod: periodSettings?.settings?.comparisonTimePeriod,
          comparisonDateRangeStart:
            periodSettings?.settings?.comparisonDateRangeStart,
          comparisonDateRangeEnd:
            periodSettings?.settings?.comparisonDateRangeEnd,
          dateRangeStart: periodSettings?.settings?.dateRangeStart,
          dateRangeEnd: periodSettings?.settings?.dateRangeEnd,
        };

        commit(MUTATIONS.DASHBOARD.SET_ITEM, dashboard);
        commit(MUTATIONS.DASHBOARD.SET_DASHBOARD, dashboard);
        commit(MUTATIONS.DASHBOARD.FIX_EMPTY_WIDGET_HEIGHT, {
          payloadLayout: rootState.dashboard.layout,
          language,
        });
      }
    } finally {
      commit(`loading/${MUTATIONS.LOADING.SET_LOADING}`, false, {
        root: true,
      });
    }
  },
  [ACTIONS.DASHBOARD.UPDATE_WIDGET_LOAD]: async ({ commit }, payload) => {
    commit(MUTATIONS.DASHBOARD.UPDATE_WIDGET_LOAD, payload);
  },
};
