import { USER_DASHBOARD } from "@/constants/userType";
import { ACTIONS, MUTATIONS } from "@/constants/vuex";
import { fetchLogin, fetchLogout } from "@/service/auth";
import { getDashboardsUserPermitted } from "@/service/dashboard";
import { getUserInfo, alterPassword } from "@/service/user";

export default {
  [ACTIONS.USER.CHANGE_PASSWORD]: async ({ commit }, payload) => {
    try {
      commit(`loading/${MUTATIONS.LOADING.SET_LOADING}`, true, {
        root: true,
      });
      await alterPassword(payload);
    } finally {
      commit(`loading/${MUTATIONS.LOADING.SET_LOADING}`, false, {
        root: true,
      });
    }
  },
  [ACTIONS.USER.LOGIN]: async (_, payload) => {
    const loginInfo = {
      ...payload,
      clientType: USER_DASHBOARD,
    };
    await fetchLogin(loginInfo);
  },
  [ACTIONS.USER.GET_INFO]: async ({ commit }, userInfo) => {
    const user = userInfo ? userInfo : await getUserInfo();
    const dashboards = await getDashboardsUserPermitted(user.id);
    commit(MUTATIONS.USER.SET_USER, {
      user,
      dashboards,
    });
  },
  [ACTIONS.USER.LOGOUT]: async ({ commit }) => {
    try {
      commit(`loading/${MUTATIONS.LOADING.SET_LOADING}`, true, {
        root: true,
      });
      await fetchLogout();
      commit(MUTATIONS.USER.REMOVE_USER_LOGGED);
      window.location.reload();
    } finally {
      commit(`loading/${MUTATIONS.LOADING.SET_LOADING}`, false, {
        root: true,
      });
    }
  },
};
