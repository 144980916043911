import instance from "@/service/instance";

const URL = "/user";

export const getUserInfo = async () => {
  const { data } = await instance(`${URL}/info`);

  return data;
};

export const lookupPortal = async (params) => {
  const { data } = await instance(
    `${URL}/portal-lookup?portal=${params.portal}`
  );

  return data;
};

export const alterPassword = async (params) => {
  const { data } = await instance.post(`${URL}/alter-password`, params);

  return data;
};
