import { DEFAULT_LANGUAGE } from "@/constants/language";
import { USER_DASHBOARD } from "@/constants/userType";

export const INITIAL_STATE = {
  logged: {
    id: undefined,
    type: USER_DASHBOARD,
    firstName: undefined,
    lastName: undefined,
    name: undefined,
    email: undefined,
    profile: { signature: undefined },
    settings: { language: undefined },
    plan: { name: undefined },
    subscriber: {
      id: undefined,
      email: undefined,
    },
    createdAt: undefined,
    language: DEFAULT_LANGUAGE,
    schedulingTool: undefined,
    mlabs: false,
    subscription: {
      type: undefined,
      trialDuration: undefined,
      startedAt: undefined,
      endsIn: undefined,
    },
    intercom: undefined,
    billing: null,
  },
  dashboards: [],
};

export default INITIAL_STATE;
